import { useState } from 'react';

export default function PrivacySettings(props) {
  const [collectData, setCollectData] = useState(props.collectData);
  function handleChange(e) {
    setCollectData(e.target.checked);
    if (e.target.checked) {
      props.onEnableDataCollection && props.onEnableDataCollection();
    } else {
      props.onDisableDataCollection && props.onDisableDataCollection();
    }
  }
  return (
    <div className="privacy-settings paragraphs">
      <header><h1>Stroke of Genius</h1></header>
      <main>
        <h2>Privacy settings</h2>
        <p>We collect anonymous gameplay data about how many shapes are drawn
          for each word. This is to help us improve the scoring bands for the
          words.</p>
        <p>We <em>do not</em> extract any information that can be used to
          identify you (e.g. IP address, location, device).</p>
        <p>We record:</p>
        <ul>
          <li>The date</li>
          <li>A random identifier generated when you start a round</li>
          <li>The word</li>
          <li>The number of shapes</li>
          <li>Whether the "stroke of genius" has been played</li>
        </ul>
        <p>If you do not wish for us to collect the above information, please
          uncheck the box below.</p>
        <p><label htmlFor="collect-data"><input type="checkbox" id="collect-data" checked={collectData} onChange={handleChange} /> Collect anonymous gameplay data</label></p>
      </main>
      <footer>
        <ul className="action-list">
          <li><button className="primary" key="main-menu" onClick={() => props.onMainMenuClick && props.onMainMenuClick()}>Main menu</button></li>
        </ul>
      </footer>
    </div>
  );
}
