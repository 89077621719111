import { useEffect } from 'react';

function disableScroll(e) {
  e.preventDefault();
  return false;
}

export default function useDisableScroll() {
  useEffect(() => {
    window.addEventListener('touchmove', disableScroll, { passive: false });
    return () => {
      window.removeEventListener('touchmove', disableScroll);
    }
  });
}