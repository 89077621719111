import './LocalGame.css';
import { useState, useRef, useLayoutEffect } from 'react';
import hideSVG from './hide.svg';
import seedrandom from 'seedrandom';
import Textfit from 'react-textfit';
import useDisableScroll from './useDisableScroll';
import { v4 as uuidv4 } from 'uuid';
import words from './words.json';

function useShuffledList(list) {
  function shuffleList() {
    return list.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  const [shuffledList, setShuffledList] = useState(shuffleList());
  const [index, setIndex] = useState(0);

  function incrementIndex() {
    if (index === list.length - 1) {
      const curWord = shuffledList[index];
      const newShuffledList = shuffleList();
      setShuffledList(newShuffledList);
      if (newShuffledList[0] === curWord) {
        // If, after shuffling, the next word would be the same as this one,
        // then skip over it. Otherwise clicking "New word" wouldn't give you a
        // new word.
        setIndex(1);
      } else {
        setIndex(0);
      }
    } else {
      setIndex(index + 1);
    }
  }

  return [shuffledList[index], incrementIndex];
}

const Phase = {
  start_of_round: Symbol('start-of-round'),
  display_word: Symbol('display-word'),
  display_shape: Symbol('display-shape'),
  end_of_round: Symbol('end-of-round'),
};

const Shapes = {
  circle: Symbol('circle'),
  rect: Symbol('rect'),
  triangle: Symbol('triangle'),
  lines: Symbol('lines'),
};

const shapes = {}
shapes[Shapes.circle] = {
  alt: 'Circle'
};
shapes[Shapes.rect] = {
  alt: 'Rectangle'
};
shapes[Shapes.triangle] = {
  alt: 'Triangle'
};
shapes[Shapes.lines] = {
  alt: 'Two intersecting lines'
};

function StartOfRound(props) {
  return <>
    <main>
      <img src={hideSVG} alt="" />
      <p>Make sure only the artist can see the screen.</p>
    </main>
    <footer>
      <ul className="action-list">
        <li><button key="reveal-word" className="primary" onClick={() => props.onRevealWordClick && props.onRevealWordClick()}>Reveal word</button></li>
      </ul>
    </footer>
  </>;
}

function DisplayWord(props) {
  const [word, nextWord] = useShuffledList(props.words);

  const lastRange = word.scores[word.scores.length - 1];
  const ranges = word.scores.map((range, index) => {
    return <li key={index}><span>{range.points}</span> {range.lower}&ndash;{range.upper}</li>;
  }).concat(<li key={-1}><span>0</span> {lastRange.upper + 1}+</li>);

  return <>
    <main>
      <Textfit mode="single" className="word" max={80}>{word.word}</Textfit>
      <ul className="score-ranges">
        {ranges}
      </ul>
    </main>
    <footer>
      <ul className="action-list">
        <li><button key="new-word" onClick={() => nextWord()}>New word</button></li>
        <li><button key="start-drawing" className="primary" onClick={() => props.onStartRoundClick && props.onStartRoundClick(word)}>Start drawing</button></li>
      </ul>
    </footer>
  </>;
}

function Shape(props) {
  // $0.offsetHeight - $0.children[0].offsetHeight - parseInt(window.getComputedStyle($0.children[0]).getPropertyValue('margin-top')) - parseInt(window.getComputedStyle($0.children[0]).getPropertyValue('margin-bottom')) - parseInt(window.getComputedStyle($0.children[0]).getPropertyValue('padding-top')) - parseInt(window.getComputedStyle($0.children[0]).getPropertyValue('padding-bottom'));
  const shapeRef = useRef(null);

  useLayoutEffect(() => {
    const shape = shapeRef.current;
    const container = shape.parentElement;

    const width = container.offsetWidth;
    let height = container.offsetHeight;
    for (let i = 0; i < container.children.length; i++) {
      const child = container.children[i];
      if (child === shape) {
        continue;
      }
      const style = window.getComputedStyle(child);
      const marginAndPadding = ['margin-top', 'margin-bottom', 'padding-top', 'padding-bottom']
        .map(prop => parseInt(style.getPropertyValue(prop)))
        .reduce((a, b) => a + b);
      height -= child.offsetHeight + marginAndPadding;
    }

    const size = Math.min(width, height);

    shape.style.width = size + 'px';
    shape.style.height = size + 'px';
  });

  const rng = seedrandom(props.index);
  if (props.shape === Shapes.circle) {
    const transform = 'rotate(' + ((rng() * 20) - 10) + ' 50 50)';
    const height = (rng() * 5) + 25;
    return <svg ref={shapeRef} viewBox="0 0 100 100" onClick={() => props.onClick && props.onClick()}>
      <g transform={transform}>
        <ellipse cx="50" cy="50" rx="45" ry={height} stroke="black" strokeWidth="5" fill="none" />
      </g>
    </svg>;
  } else if (props.shape === Shapes.rect) {
    const transform = 'rotate(' + ((rng() * 20) - 10) + ' 50 50)';
    return <svg ref={shapeRef} viewBox="0 0 100 100" onClick={() => props.onClick && props.onClick()}>
      <g transform={transform}>
        <rect x="10" y="20" width="80" height="60" stroke="black" strokeWidth="5" fill="none" />
      </g>
    </svg>;
  } else if (props.shape === Shapes.triangle) {
    const cornerIndex = rng() * 4 | 0;
  
    let p1, p2, p3;
    if (cornerIndex === 0) {
      p1 = {x: 20, y: 20};
      p2 = {x: 90, y: (rng() * 30) + 30};
      p3 = {x: (rng() * 30) + 30, y: 90};
    } else if (cornerIndex === 1) {
      p1 = {x: 80, y: 20};
      p2 = {x: (rng() * 30) + 30, y: 90};
      p3 = {x: 10, y: (rng() * 30) + 30};
    } else if (cornerIndex === 2) {
      p1 = {x: 80, y: 80};
      p2 = {x: 10, y: (rng() * 30) + 30};
      p3 = {x: (rng() * 30) + 30, y: 10};
    } else if (cornerIndex === 3) {
      p1 = {x: 20, y: 80};
      p2 = {x:(rng() * 30) + 30, y: 10};
      p3 = {x: 90, y: (rng() * 30) + 30};
    }
    const points = p1.x + ',' + p1.y + ' ' + p2.x + ',' + p2.y + ' ' + p3.x + ',' + p3.y + ' ' + p1.x + ',' + p1.y;
    return <svg ref={shapeRef} viewBox="0 0 100 100" onClick={() => props.onClick && props.onClick()}>
      <polygon points={points} stroke="black" strokeWidth="5" fill="none" />
    </svg>
  } else if (props.shape === Shapes.lines) {
    const line1Start = {x: 10, y: (rng() * 30) + 60};
    const line1End = {x: 90, y: (rng() * 30) + 10};
    const line2Start = {x: (rng() * 30) + 10, y: 30};
    const line2End = {x: (rng() * 30) + 60, y: 70};
    const transform = rng() < 0.5 ? '' : 'translate(0 100) scale(1 -1)';
    return <svg ref={shapeRef} viewBox="0 0 100 100" onClick={() => props.onClick && props.onClick()}>
      <g transform={transform}>
        <line x1={line1Start.x} y1={line1Start.y} x2={line1End.x} y2={line1End.y} stroke="black" strokeWidth="5" />
        <line x1={line2Start.x} y1={line2Start.y} x2={line2End.x} y2={line2End.y} stroke="black" strokeWidth="5" />
      </g>
    </svg>;
  }
}

function DisplayShape(props) {
  const [shape, nextShape] = useShuffledList([
    Shapes.circle, Shapes.circle, Shapes.circle,
    Shapes.rect, Shapes.rect, Shapes.rect,
    Shapes.triangle, Shapes.triangle, Shapes.triangle,
    Shapes.lines, Shapes.lines, Shapes.lines]);
  const [shapeCount, setShapeCount] = useState(1);
  const [strokeOfGeniusUsed, setStrokeOfGeniusUsed] = useState(false);

  const lastRange = props.word.scores[props.word.scores.length - 1];
  const scoreRanges = [...props.word.scores, {
      lower: lastRange.upper + 1,
      upper: lastRange.upper + 1,
      points: 0
    }];

  let score;
  const scoreSections = scoreRanges.flatMap((range, rangeIndex) => {
    const rangeSize = (range.upper - range.lower) + 1;
    const isScoringZero = (rangeIndex === scoreRanges.length - 1) && shapeCount >= range.lower;

    const guessCounts = [...Array(rangeSize).keys()].map(i => {
      const index = i + range.lower;
      const className = (index === shapeCount || isScoringZero) ? 'active' : '';
      const label = index + ((rangeIndex === scoreRanges.length - 1) ? '+' : '');
      return <li className={className} key={label}>{label}</li>;
    });
    const isInRange = shapeCount >= range.lower && shapeCount <= range.upper;
    let className = '';
    if (isScoringZero || isInRange) {
      className = 'active';
      score = range.points;
    }
    return <div className="score-section" key={rangeIndex}><ul>{guessCounts}</ul><p className={className}>{range.points}</p></div>;
  });

  function sendGameReport(reportShapeCount, reportStrokeOfGeniusUsed) {
    if (!props.collectData) {
      return;
    }

    fetch('https://648sh1fj56.execute-api.eu-west-1.amazonaws.com/game-report', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        game_id: props.gameId,
        word: props.word.word,
        shapes: reportShapeCount,
        stroke_of_genius: reportStrokeOfGeniusUsed
      })
    });
  }

  function drawNextShape() {
    const newShapeCount = shapeCount + 1;
    setShapeCount(newShapeCount);
    nextShape();
    sendGameReport(newShapeCount, strokeOfGeniusUsed);
  }

  const interactionMode = matchMedia('(pointer:fine)').matches ? 'Click' : 'Tap';
  const endRoundClass = shapeCount >= scoreRanges[scoreRanges.length - 1].lower ? 'attention' : 'primary';

  return <>
    <main>
      <div className="score">{scoreSections}</div>
      <Shape shape={shape} index={shapeCount} onClick={drawNextShape} />
    </main>
    <footer>
      <p className="info">{interactionMode} the shape to reveal the next one</p>
      <ul className="action-list">
        <li><button key="use-sog" onClick={() => { setStrokeOfGeniusUsed(true); sendGameReport(shapeCount, true); }} disabled={strokeOfGeniusUsed}>Use Stroke of Genius</button></li>
        <li><button key="end-round" className={endRoundClass} onClick={() => { sendGameReport(shapeCount, strokeOfGeniusUsed); props.onEndRoundClick && props.onEndRoundClick(score) }}>End round</button></li>
      </ul>
    </footer>
  </>;
}

function EndOfRound(props) {
  let textUI;
  if (props.score > 0) {
    const plural = props.score === 1 ? '' : 's';
    textUI = <p>The artist and the guesser score {props.score} point{plural}!</p>;
  } else {
    textUI = <p>No one scores any points :(</p>;
  }
  return <>
    <main>
      {textUI}
      <p className="final-score">{props.score}</p>
    </main>
    <footer>
      <ul className="action-list">
        <li><button key="main-menu" onClick={() => props.onMainMenuClick && props.onMainMenuClick()}>Main menu</button></li>
        <li><button key="new-round" className="primary" onClick={() => props.onNewRoundClick && props.onNewRoundClick()}>New round</button></li>
      </ul>
    </footer>
  </>;
}

export default function LocalGame(props) {
  const [phase, setPhase] = useState(Phase.start_of_round);
  const [word, setWord] = useState();
  const [score, setScore] = useState(0);
  const [gameId, setGameId] = useState();
  useDisableScroll();

  function handleStartOfRound(word) {
    setPhase(Phase.display_shape);
    setWord(word);
    setGameId(uuidv4());
  }

  let phaseUI;
  if (phase === Phase.start_of_round) {
    phaseUI = <StartOfRound onRevealWordClick={() => setPhase(Phase.display_word)} />
  } else if (phase === Phase.display_word) {
    phaseUI = <DisplayWord onStartRoundClick={handleStartOfRound} words={words} />;
  } else if (phase === Phase.display_shape) {
    phaseUI = <DisplayShape word={word} gameId={gameId} collectData={props.collectData} onEndRoundClick={score => {setPhase(Phase.end_of_round); setScore(score)}} />;
  } else if (phase === Phase.end_of_round) {
    phaseUI = <EndOfRound
      score={score}
      onMainMenuClick={() => props.onMainMenuClick && props.onMainMenuClick()}
      onNewRoundClick={() => setPhase(Phase.start_of_round)} />;
  }

  console.log(gameId);

  return <div className = "game">
    <div className={`mask ${phase === Phase.start_of_round || phase === Phase.display_word ? '' : 'hidden'}`}></div>
    <header><h1>Stroke of Genius</h1></header>
    {phaseUI}
  </div>;
}