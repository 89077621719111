export default function Contact(props) {
  return (
    <div className="contact paragraphs">
      <header><h1>Stroke of Genius</h1></header>
      <main>
        <h2>Contact</h2>
        <p>Stroke of Genius was designed and programmed by Andrew January.</p>
        <p>You can contact me by emailing <a href="mailto:andrewjanuary@gmail.com">andrewjanuary@gmail.com</a>.</p>
        <p>You can follow me on twitter <a href="https://twitter.com/ajanuary">@ajanuary</a>.</p>
      </main>
      <footer>
        <ul className="action-list">
          <li><button className="primary" key="main-menu" onClick={() => props.onMainMenuClick && props.onMainMenuClick()}>Main menu</button></li>
        </ul>
      </footer>
    </div>
  );
}
