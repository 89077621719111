import './Instructions.css';
import start_of_play from './images/instructions-start-of-play.png';
import display_word from './images/instructions-display-word.png';
import display_shape from './images/instructions-display-shape.png';
import new_shape from './images/instructions-new-shape.png';
import stroke_of_genius from './images/instructions-sog.png';
import end_of_round from './images/instructions-end-of-round.png';
import triangle from './images/instructions-triangle.png';
import rectangle from './images/instructions-rectangle.png';
import circle from './images/instructions-circle.png';
import lines from './images/instructions-lines.png';


export default function Instructions(props) {
  return (
    <div className="instructions">
      <header><h1>Stroke of Genius</h1></header>
      <main>
        <h2>Equipment</h2>
        <p>As well as this app, you will need several sheets of blank paper
          and a pen or pencil to draw with.</p>
        <h2>Play</h2>
        <p>Pick a player to be the artist.</p>
        <p className="aside">The artist cannot speak or communicate except by drawing shapes.</p>
        <p>As an artist:</p>
        <p>Make sure no one else can see the screen, then tap “Reveal word”.</p>
        <img src={start_of_play} className="screenshot" alt="The start of round screen with a button saying 'Reveal word'" />
        <p>Memorize the word, then tap “Start drawing”.</p>
        <img src={display_word} className="screenshot" alt="A screen showing the word to draw, a button saying 'New word' and a button saying 'Start drawing'" />
        <p className="aside">If you think the word is too hard, tap “New word”.</p>
        <p>Draw the shape shown on the screen <em>once</em>.</p>
        <img src={display_shape} className="screenshot" alt="A screen showing an ellipse, a button saying 'Use Stroke of Genius' and a button saying 'End round'" />
        <p>You can stretch, rotate and flip the shape as much as you like, as long
          as it is still recognizable as the shape on the screen. i.e. You can’t
          draw a “very thin rectangle” that is actually a straight line.</p>
        <p className="aside">At this point, it is okay for other players to see the screen.</p>
        <p>The other players should try to guess what word you are drawing.</p>
        <p>You can tap the shape to reveal a new one. You must then draw this new shape <em>once</em>.</p>
        <img src={new_shape} className="screenshot" alt="A screen showing two lines, a button saying 'Use Stroke of Genius' and a button saying 'End round'" />
        <p className="aside">The more shapes you draw, the fewer points the game will award. You can see how many shapes you have drawn, and hence how many points the game will be award, at the top of the screen.</p>
        <p>Once in the round you may tap the “Stroke of genius” button to draw any of the standard shapes (triangle, rectangle, circle, lines) <em>once</em>.</p>
        <img src={stroke_of_genius} className="screenshot" alt="A screen with the 'Stroke of genius' button grayed out after being tapped" />
        <p>If someone correctly guesses the word, tap “End round” and award yourself and the guesser the points shown on the screen.</p>
        <p className="aside">After drawing 10 or more shapes, the game will award no points. You should tap "End round".</p>
        <img src={end_of_round} className="screenshot" alt="A screen showing that the artist and guesser score 3 points, a button saying 'Main menu' and a button saying 'New round'" />
        <p>The player to your left becomes the new artist. Tap the “New round” button and hand them the device.</p>
        <h2>Shapes</h2>
        <h3>Triangle</h3>
        <img src={triangle} className="shape" alt="A triangle" />
        <p>A shape with three straight sides.</p>
        <h3>Rectangle</h3>
        <img src={rectangle} className="shape" alt="A rectangle" />
        <p>A shape with four straight sides and four 90&deg; corners.</p>
        <h3>Circle</h3>
        <img src={circle} className="shape" alt="A circle" />
        <p>An ellipse with a smooth, round shape.</p>
        <h3>Lines</h3>
        <img src={lines} className="shape" alt="Two lines that cross-over" />
        <p>Two straight lines that cross-over or touch each other.</p>
      </main>
      <footer>
        <ul className="action-list">
          <li><button className="primary" key="main-menu" onClick={() => props.onMainMenuClick && props.onMainMenuClick()}>Main menu</button></li>
        </ul>
      </footer>
    </div>
  );
}
