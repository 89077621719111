import { PLAY_SCREEN, INSTRUCTIONS_SCREEN, PRIVACY_SCREEN, CONTACT_SCREEN } from './screens';

export default function Menu(props) {
  return (
    <div className="menu">
      <header>
      </header>
      <main>
        <h1>Stroke of Genius</h1>
        <ul className="action-list">
          <li><button className="primary" onClick={() => props.onItemClick && props.onItemClick(PLAY_SCREEN)}>Play</button></li>
          <li><button onClick={() => props.onItemClick && props.onItemClick(INSTRUCTIONS_SCREEN)}>How to play</button></li>
          <li><button onClick={() => props.onItemClick && props.onItemClick(PRIVACY_SCREEN)}>Privacy settings</button></li>
          <li><button onClick={() => props.onItemClick && props.onItemClick(CONTACT_SCREEN)}>Contact</button></li>
        </ul>
      </main>
      <footer></footer>
    </div>
  );
}
