import './App.css';
import { MENU_SCREEN, INSTRUCTIONS_SCREEN, PLAY_SCREEN, PRIVACY_SCREEN, CONTACT_SCREEN } from './screens';
import { use100vh } from 'react-div-100vh';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import Contact from './Contact';
import Instructions from './Instructions'
import LocalGame from './LocalGame'
import Menu from './Menu'
import PrivacySettings from './PrivacySettings'

export default function App() {
  const [screen, setScreen] = useState(MENU_SCREEN);
  const [cookies, setCookie, removeCookie] = useCookies(['collectData']);

  const collectData = cookies.collectData === undefined;

  let screenUI;
  if (screen === MENU_SCREEN) {
    screenUI = <Menu onItemClick={item => setScreen(item)} />;
  } else if (screen === INSTRUCTIONS_SCREEN) {
    screenUI = <Instructions onMainMenuClick={() => setScreen(MENU_SCREEN)} />;
  } else if (screen === PLAY_SCREEN) {
    screenUI = <LocalGame onMainMenuClick={() => setScreen(MENU_SCREEN)} collectData={collectData} />;
  } else if (screen === PRIVACY_SCREEN) {
    screenUI = <PrivacySettings
      onMainMenuClick={() => setScreen(MENU_SCREEN)}
      collectData={collectData}
      onEnableDataCollection={() => removeCookie('collectData', { sameSite: 'strict' })}
      onDisableDataCollection={() => setCookie('collectData', 'disable', { sameSite: 'strict' })}
      />;
  } else if (screen === CONTACT_SCREEN) {
    screenUI = <Contact onMainMenuClick={() => setScreen(MENU_SCREEN)} />;
  }

  const height = use100vh();

  return <div className="App" style={{ height: `calc(${height}px - 2rem)` }}>
    {screenUI}
  </div>;
}
